import React from "react";
import {useStore} from "../services/state/useStore";
import {useApi} from "../services/useApi";
import {Col, Row} from "react-bootstrap";
import ClubSelectorDropdown from "../components/dropdown/clubselector/ClubSelectorDropdown";
import ReviewSearchBar from "../parts/reviews/dashboard/ReviewSearchBar";
import ReviewMetricsSummary from "../parts/reviews/dashboard/ReviewMetricsSummary";
import ReviewsTable from "../parts/reviews/dashboard/ReviewsTable";

interface Props {

}

const Reviews = ({}: Props) => {

    const { currentUser } = useStore();
    const {  } = useApi();

    return (
        <div className="p-3">
            <Row>
                <Col xs={8} className="pt-3">
                    <h3 className="welcomeHeading">Reviews</h3>
                    <p className="welcomeSubHeading">Track how your caddie programme is performing.</p>
                </Col>
                <Col xs={4} className="d-flex justify-content-end align-items-end pb-3">
                    <ClubSelectorDropdown/>
                </Col>
            </Row>

            <Row>
                <ReviewSearchBar />
            </Row>

            <Row>
                <ReviewMetricsSummary />
            </Row>

            <ReviewsTable />
        </div>
    )
}

export default Reviews;