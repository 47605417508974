import React from 'react';
import {Col, Form} from "react-bootstrap";

interface Props {

}

const ReviewSearchBar = ({}: Props) => {

    return (
        <Col xs={12} className="pt-3">
            <div style={styles.container}>
                <h6 style={styles.inputLabel}>Caddie Search</h6>
                <Form.Control type={"text"} placeholder={"Enter caddie name..."} />
            </div>
        </Col>
    )
}

export default ReviewSearchBar;

const styles: Record<string, React.CSSProperties> = {
    container: {
        backgroundColor: "white",
        width: '100%',
        padding: '1em',
        borderRadius: '5px',
        marginBottom: '1em'
    },
    inputLabel: {
        fontWeight: 400
    },
};
