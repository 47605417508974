import React, {useEffect} from 'react';
import Avatar from "../../../components/Avatar";
import {DeleteOutline} from "@material-ui/icons";
import {Table} from "react-bootstrap";

interface Props {

}

interface Review {
    caddieId: string
    name: string
    attributes: {
        knowledge: number
        productivity: number
        punctuality: number
    },
    rating: number
    count: number
}

const ReviewsTable = ({}: Props) => {

    const [reviews, setReviews] = React.useState<Review[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);

    useEffect(() => {
        setReviews([
            {
                caddieId: "123",
                name: "Caddie Name",
                attributes: {
                    knowledge: 60,
                    productivity: 60,
                    punctuality: 60,
                },
                rating: 4.65,
                count: 60
            },
            {
                caddieId: "1234",
                name: "Caddie Name",
                attributes: {
                    knowledge: 80,
                    productivity: 95,
                    punctuality: 10,
                },
                rating: 4.90,
                count: 43
            },
            {
                caddieId: "12345",
                name: "Caddie Name",
                attributes: {
                    knowledge: 99,
                    productivity: 95,
                    punctuality: 97,
                },
                rating: 5,
                count: 127
            },
        ])
    }, []);


    return (
        <>
            <Table borderless>
                <thead className={"caddiesTable_headingContainer"}>
                <tr>
                    <td>Name</td>
                    <td>Knowledge</td>
                    <td>Productivity</td>
                    <td>Punctuality</td>
                    <td>Rating</td>
                    <td>Count</td>
                    {/*<td></td>*/}
                </tr>
                </thead>
                <tbody className={"caddiesTable_bodyContainer"}>
                {!loading && reviews.map(review => {
                    return <tr key={review.caddieId}>
                        <td>
                            <Avatar size={"xs"} user={review.caddieId}/>
                            {review.name}
                        </td>
                        <td>{review.attributes.knowledge}%</td>
                        <td>{review.attributes.productivity}%</td>
                        <td>{review.attributes.punctuality}%</td>
                        <td>{review.rating}</td>
                        <td>{review.count}</td>
                        <td>
                            <span
                                className={"caddiesTable_viewProfileBtn"}
                                onClick={() => {}}>
                                VIEW REVIEWS
                            </span>
                        </td>
                    </tr>
                })}
                </tbody>
            </Table>
        </>
    )
}

export default ReviewsTable;