import React from 'react';
import {Col, Form} from "react-bootstrap";
import {BarChart, StarBorderOutlined, StarHalfOutlined, StarOutlined} from "@material-ui/icons";

interface Props {

}

const ReviewSearchBar = ({}: Props) => {

    return (
        <Col xs={12} className="pt-3">
            <div style={styles.container}>
                <div style={styles.metricContainer}>
                    <div style={styles.starsContainer}>
                        <StarOutlined />
                        <StarOutlined />
                        <StarOutlined />
                        <StarOutlined />
                        <StarHalfOutlined />
                        (4.7)
                    </div>

                    <h6 style={styles.inputLabel}>Average Rating</h6>
                </div>
                <div style={styles.verticalLine}/>
                <div style={styles.metricContainer}>
                    <BarChart

                    />
                    <h6 style={styles.inputLabel}>Point System</h6>
                </div>
                <div style={styles.verticalLine}/>
                <div style={styles.metricContainer}>
                    <span style={styles.reviewCountMetric}>
                        236
                    </span>

                    <h6 style={styles.inputLabel}>Review Count</h6>
                </div>
            </div>
        </Col>
    )
}

export default ReviewSearchBar;

const styles: Record<string, React.CSSProperties> = {
    container: {
        backgroundColor: "white",
        width: '100%',
        padding: '1em',
        borderRadius: '5px',
        marginBottom: '1em',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    metricContainer: {
        justifyContent: 'center',
        width: '30%',
        display: 'flex',
        flexDirection: 'column',
        margin: '1em 0',
        alignItems: 'center'
    },
    inputLabel: {
        fontWeight: 400,
        marginTop: '0.25em',
        fontSize: '1em'
    },
    verticalLine: {
        width: '1px',
        backgroundColor: '#ced4da'
    },
    starsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '30%',
    },
    reviewCountMetric: {
        fontSize: '3em',
        fontWeight: 400,
    }
};
